.file-name[data-v-7a0a6491] {
  color: #6f91c1;
  cursor: pointer;
  text-decoration: underline;
}
.headInfo[data-v-7a0a6491] {
  padding: 15px 12px 30px 12px;
  font-size: 14px;
  color: #3f4448;
}
.headInfo label[data-v-7a0a6491] {
  width: 100px;
}
.file-table[data-v-7a0a6491] {
  width: 100%;
  text-align: center;
  border-color: #cccccc;
  font-size: 14px;
  color: #606266;
}